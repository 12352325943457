<template>
  <div>
    <b-modal
      :id="id"
      :title="modalTitle"
      :ok-title="okTitle"
      :cancel-title="cancelTitle"
      :size="size"
      v-on="$listeners"
      v-bind="$attrs"
    >
      <slot name="modal-content"></slot>
    </b-modal>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },
    modalTitle: {
      type: String,
      required: true
    },
    okTitle: {
      type: String,
      required: true
    },
    cancelTitle: {
      type: String
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "lg"
    }
  }
};
</script>

<style lang="scss" scoped></style>
