import { render, staticRenderFns } from "./BaseModal.vue?vue&type=template&id=7853360e&scoped=true&"
import script from "./BaseModal.vue?vue&type=script&lang=js&"
export * from "./BaseModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7853360e",
  null
  
)

export default component.exports