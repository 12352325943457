<template>
  <div>
    <b-form-group :description="help" :label="label">
      <b-form-input
        :value="value"
        v-bind="$attrs"
        @input="updateValue"
        :readonly="readonly"
        :disabled="disabled"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ""
    },
    help: {
      type: String
    },
    readonly: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    defaultValue: {
      type: [String, Number]
    }
  },
  data() {
    return {
      value: ""
    };
  },
  methods: {
    updateValue(e) {
      this.value = e;
      this.$emit("input", e);
    },
    setDefaultValue() {
      this.value = this.defaultValue;
    }
  },
  beforeUpdate() {
    if (this.defaultValue) {
      this.setDefaultValue();
    } else {
      this.value = "";
    }
  }
};
</script>
